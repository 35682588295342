@font-face {
  font-family: 'BegumSans-Medium';
  src: url('./assets/font/BegumSans-SemiBold.otf') format('otf'),
    url('./assets/font/BegumSans-SemiBold.svg') format('svg'),
    url('./assets/font/BegumSans-SemiBold.ttf') format('truetype'),
    url('./assets/font/BegumSans-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BegumSans-SemiBold';
  src: url('./assets/font/BegumSans-SemiBold.otf') format('opentype'),
    url('./assets/font/BegumSans-SemiBold.svg') format('svg'),
    url('./assets/font/BegumSans-SemiBold.ttf') format('truetype'),
    url('./assets/font/BegumSans-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S100';
  src:
    url('./assets/font/Sequel100Wide95/font.woff') format('woff'),
    url('./assets/font/Sequel100Wide95/font.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SSB';
  src:
    url('./assets/font/SequelSansBold/font.woff') format('woff'),
    url('./assets/font/SequelSansBold/font.woff2') format('woff2');
  font-weight: 600;
  font-style: 600;
}

@font-face {
  font-family: 'SSM';
  src:
    url('./assets/font/SequelSansMedium/font.woff') format('woff'),
    url('./assets/font/SequelSansMedium/font.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
