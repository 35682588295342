// ABBRUNZATI SKIN <- JORIS SKIN <- FE SKIN <- BF SKIN <- JIRAFA SKIN
@import 'fonts';
// font-family: 'BegumSans-Medium';
// font-family: 'BegumSans-SemiBold';

@import './cursor';

body {
  color: $royblack;
  letter-spacing: -0.02em;
}

h1,
h2 {
  text-transform: uppercase;
  line-height: 1.125;
}

h3,
h4,
h5,
h5 {
  text-transform: uppercase;
  line-height: 1.25;
}

h1,
h2,
h3 {
  letter-spacing: 0.075em;
}

h4,
h5 {
  //font-family: 'BegumSans-Medium' !important;
  letter-spacing: 0.075em;
}

b,
strong {
  font-family: 'SSB', $font-family-sans-serif;
  letter-spacing: -0, 02em;
}

.modal-title,
legend {
  font-family: 'S100', $font-family-sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.075em;
}

small,
.small {
  font-size: 0.75rem;
}

// body,
// .product-content {
//   background-color: $roybeige;
// }

#root {
  padding-top: 0;
}

#svg13 {
  fill: $roybeige;
}

.add-product.btn {
  //.fa-shopping-bag {display: none;}
}

.add-product.btn,
.btn-primary {
  // .nigiri-cta .btn-block,
  // .shoppingcart-finisher .btn,
  // .checkout .btn,
  // .order-details .btn,
  // .modal-dialog .btn-primary {
  color: $black;
  background-color: $roygreen;
  border: 1px solid §primary;
}

.shoppingcart-finisher,
.checkout .sticky-bottom {
  background-color: rgba($roygreen, 0.7) !important;
  .btn.btn-primary {
    background-color: $royblack;
    color: $white;
  }
  .btn-link {
    background-color: transparent;
    color: $black;
    text-decoration: underline;
  }
}

.loading-spinner-content {
  text-transform: uppercase;
}

.loading-spinner-content {
  background-color: $roybeige !important;
}

.fa-shopping-cart {
  color: $roygreen;
}

.far,
.fal {
  font-weight: 600;
}

.header-img {
  // padding-left: 0px;
  // padding-right: 0px;
}

.title {
  @include media-breakpoint-down(sm) {
    font-size: 2rem !important;
  }
}

// NAV

.navbar-light {
  //background: transparent !important;
  //transition: background 0.2s;
  background-color: rgba($color: $roybeige, $alpha: 0.9) !important;
  color: $white;
  // body[data-scrolled] & {
  //   background-color: $white !important;
  // }
}

// .product-listing-page .navbar-brand {
//   opacity: 0;
//   transform: translateY(-10px);
//   transition: all 0.1s;
//   transition-delay: 0;
// }

// body[data-scrolled] {
//   .navbar-brand {
//     opacity: 1;
//     transition-delay: 0.2s;
//     transform: translateY(0);
//   }
// }

#header .navbar {
  background-color: $white;
  padding: 1rem;
  font-size: $h5-font-size;
}

#header .navbar .navbar-brand {
  img {
    height: 2rem;
    max-height: 2rem;

    @include media-breakpoint-up(md) {
      height: 5rem;
      max-height: 5rem;
    }

    &:hover,
    &:focus {
      filter: opacity(0.66);
    }
  }
}

.navbar .hamburger {
  color: $royblack !important;
  @include media-breakpoint-down(sm) {
    padding-left: 0px;
  }
}

.navbar .hamburger.is-active .hamburger-inner,
.navbar .hamburger.is-active .hamburger-inner:after,
.navbar .hamburger.is-active .hamburger-inner:before {
  background-color: $royblack !important;
}

.navbar .hamburger-inner,
.navbar .hamburger-inner:after,
.navbar .hamburger-inner:before {
  height: 2px;
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

.offcanvas-collapse {
  top: 9rem !important;

  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    background-color: rgba($color: $royblack, $alpha: 0.9) !important;
    a {
      color: #fff;
    }
    a.active,
    a:hover {
      color: $gray-200;
      //font-style: italic;
    }
    // font-family: $font-family-heading;
    font-size: $h5-font-size;
    text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
}

.offcanvas-collapse {
  box-shadow: 15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background-color: rgba($color: $white, $alpha: 0.97) !important;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  h5 {
    font-size: $h5-font-size;
    text-transform: uppercase;
    color: $gray-900 !important;
  }
}

// MAKI

.maki {
  //@extend .p-3;
  .badge-container {
    top: 1rem !important;
  }
  .card-body {
    flex-direction: column;
    min-height: 100px;

    .card-title {
      text-align: left;
      justify-content: normal;

      .title {
        text-transform: uppercase;
        font-size: $h5-font-size !important;
        font-family: $headings-font-family;
        line-height: 1.25;
        letter-spacing: 0.1em;
        color: $royblack;
        //@extend .text-truncate;
      }
      .sub-title {
        //font-size: smaller;
        font-size: 0.9rem;
        //@extend .mb-2;
      }
      .price {
        display: none !important;
      }
    }
    .tags .tag {
      padding: 9px;
      font-size: 0.8rem;
      color: rgba($color: $royblack, $alpha: 0.5);
      background-color: $roybeige;
      position: absolute;
      top: 10px;
      right: 0;
    }
    .badge-danger {
      position: absolute;
      z-index: 2;
      right: 0;
      top: -15px;
      text-transform: uppercase;
    }
  }
  &:hover {
    //box-shadow: $box-shadow-xl;
    transition: all 0.15s ease-in-out;
  }
}

.maki .sold-out-status {
  // background-color: $royblack !important;
  // color: $roybeige;
  // border: 2px solid $royblack;
  // aspect-ratio: 1 / 1;
  // padding: 0;
  // border-radius: 50%;
  // height: 4rem;
  // line-height: 3.7rem;
  // text-transform: uppercase;
  // transform: translate(-1rem, 1rem) rotate(10deg);
}

// NIGIRI

.nigiri.card {
  background-color: transparent;
  border: 0;
  //border-bottom: 1px solid $gray-300;
  border-top: 1px solid $gray-200;
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;

  .nigiri-content {
    padding: 1.25rem 0 1.25rem 0;
    grid-template-columns: 115px 2fr auto;
    //padding-top: 0;
    //margin-top: 0.2rem;
    color: $royblack;

    @include media-breakpoint-down(md) {
      display: table;
    }
  }
  .nigiri-date {
    font-size: $h2-font-size;
    font-family: 'SSB', $font-family-sans-serif;
    text-align: left;
    color: $royblack;
    @include media-breakpoint-up(lg) {
      flex-direction: column;
    }
  }
  .nigiri-body {
    display: flex;
    align-self: center;
    font-size: $h2-font-size;
    letter-spacing: 0.1em;
    border-left: 0;
    text-transform: uppercase;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      line-height: 2rem;
    }
    .location {
      font-family: 'S100';
      line-height: 1.25;
      margin-bottom: 0;
      @include media-breakpoint-up(lg) {
        margin-left: 1rem;
      }
      .city {
        display: block;
      }
      .location-name {
        display: block;
        font-family: 'SSM';
        //font-size: $font-size-base;
        text-transform: none;
        letter-spacing: 0;
      }
    }
  }
  .nigiri-tags {
    line-height: 0;
    @include media-breakpoint-only(md) {
      padding-bottom: 1.25rem;
    }
    .tag {
      padding: 0;
      font-size: 0.8rem;
      color: rgba($color: $royblack, $alpha: 0.5);
      background-color: $roybeige;
    }
    //SPECIAL STYLING KULT RELEASE EVENT TAG
    .tag-7780 {
      position: absolute;
      padding-bottom: 2.5px;
      padding-top: 3px;
      padding-left: 3px;
      padding-right: 3px;
      border: 1px solid black;
      font-size: 0.9rem;
      top: -0.62rem;
      left: 0.25rem;
      @media screen and (max-width: 768px) {
        left: 0rem;
        font-size: 0.6rem;
        max-width: 52%;
        top: -0.75rem;
        text-wrap: wrap;
      }
    }
  }

  .badge-danger {
    // background-color: $royblack;
    // color: $roybeige;
    // border: 2px solid $royblack;
    z-index: 2;
    // padding: 0;
    right: 0;
    // top: 0;
    text-transform: uppercase;

    // &.sold-out-status_sold_out {
    //   height: 3rem;
    //   line-height: 2.7rem;
    //   border-radius: 50%;
    //   aspect-ratio: 1 / 1;
    //   transform: translate(-0.5rem, 0rem) rotate(10deg) scale(0.7);

    //   @include media-breakpoint-down(md) {
    //     transform: translate(-3rem, 4.5rem) rotate(10deg);

    //     @include media-breakpoint-down(sm) {
    //       transform: translate(-1.5rem, 1rem) rotate(10deg);
    //     }
    //   }
    // }
  }
  .title {
    font-size: 0.6em;
    margin-bottom: 0;
    color: $royblack;
    font-family: $font-family-sans-serif !important;
  }
  .subtitle {
    font-size: 0.5em;
    margin-bottom: 0;
    color: $gray-500;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      margin-bottom: 0.5rem;
    }
  }
  .location {
    // font-family: $font-family-sans-serif !important;
    // font-size: $h3-font-size !important;
    color: rgba($color: $royblack, $alpha: 0.9);
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }
  .nigiri-cta {
    grid-row: auto;
  }
}

// PDP

body.ticket-detail-page {
  .card {
    border: 0;
  }
  .card.article-title {
    background-color: $white;
    .super-title {
      margin-bottom: 0.35rem;
      display: block;
    }
    h1 {
      font-size: $h1-font-size;
      small {
        margin-top: 0.25rem;
        color: rgba($color: $royblack, $alpha: 0.6);
      }
    }
  }
  .product-info-bar {
    background-color: $white;
    //box-shadow: none;
    .DayPicker-Day--disabled {
      color: $gray-500;
    }
    .DayPicker-Day--today {
      color: $roygreen;
      font-weight: 900;
    }
  }
}

.detail-page {
  h1 {
    margin-top: 0;
    color: $royblack;
    text-transform: uppercase;
    // font-size: $h2-font-size;
    .sub-title {
      color: $royblack;
      font-family: 'SSM';
      letter-spacing: 0;
      font-size: $h3-font-size;
      text-transform: none;
    }
  }
  .alert-info {
    background-color: $royblack;
    color: $white;
    border-color: $royblack;
    text-align: center;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: $roybeige;
}

// .blurry-background-image:before {
//   background-image: none;
// }

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// FOOTER

#footer {
  background-color: $royblack;
  color: $roybeige;
  a {
    color: $roybeige;
  }
}

#product-listing {
  padding-top: 2rem;
}

.hero-slider {
  margin-bottom: 0;
}

.stage-wrapper {
  box-shadow: $box-shadow-xl;
  background-color: #fbccb0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.stage {
  box-shadow: $box-shadow-xl;
}

.boxheader {
  text-transform: uppercase;
  text-align: center;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// FIXES

.tt-sticky-top {
  top: 0;
}

// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      background-color: $white !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    colgroup {
      display: none;
    }

    div.invisible.spinner-border {
      display: none !important;
    }

    button.add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -0.5rem;
  }
}

// marquee
@keyframes marquee {
  from {
    transform: translate(0px);
  }
  to {
    transform: translate(calc(-100vw - 100%));
  }
}

.marquee {
  width: 100vw;
  overflow: hidden;
  white-space: nowrap;
  border: none;
  margin-bottom: 0;
  background: $royblack;
  color: $roybeige;

  &:hover {
    #m1 {
      animation: none;
      left: 0px;
      text-align: center;
      width: auto;
    }
  }
  #m1 {
    position: relative;
    top: 0;
    left: 100vw;
    width: fit-content;
    // float: right;
    animation: marquee 20s linear infinite;
    @include media-breakpoint-up(md) {
      animation: marquee 20s linear infinite;
    }
  }
  #m2 {
    position: relative;
    bottom: 2rem;
    left: 100vw;
    // float: right;
    animation: marquee 30s linear infinite;
    animation-delay: 10s;
  }
  .close {
    color: $roybeige;
    // background: black;
    opacity: 1;
    margin-top: 12px;
    // border: 15px solid black;
    // border-top: 12px solid black;
    right: 25px;
    width: 22.5px;
    height: 22.5px;
    padding: 11.25px;
    background-image: url('./assets/images/cancel-1.svg');
    background-repeat: no-repeat;

    span {
      display: none;
    }
  }
  .close:hover {
    opacity: 1 !important;
    color: $gray-400 !important;
  }
}

// special flower cursor
@media (hover: hover) and (pointer: fine) {
  * {
    // cursor: none !important;
  }
  .rose-cursor {
    z-index: 1051;
    left: -64px;
    top: -64px;
    width: 64px;
    height: 64px;

    background-image: url($cursorImage);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    pointer-events: none;
    transition: transform 0.2s ease;
    transform-origin: 50%;

    &.hover {
      transform: scale(1.25);
    }

    &.active {
      transform: scale(0.75);
    }
  }
}

// Styling Bundle-modal

body.product-detail-page {
  .modal-dialog {
    @include media-breakpoint-up(sm) {
      max-width: 600px;
    }
  }
  .radio-list-group {
    box-shadow: none !important;
  }
  .kachel-header {
    background-color: $white;
    border-color: transparent;
    &.active {
      border-color: $roybeige;
    }
    .media {
      justify-content: center !important;
      flex-direction: column;
      .product-thumb {
        margin-right: 0 !important;
        @extend .mb-2;
        width: 50%;
        height: auto;
        border-radius: 0;
      }
    }
    h5 {
      font-size: 1rem !important;
      text-align: center;
      letter-spacing: 0;
    }
    .custom-radio {
      display: none;
    }
  }
  .kachel-container {
    display: flex;
    padding-bottom: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .custom-radio {
      display: none;
    }
    .kachel {
      img {
        display: none;
      }
      background-color: transparent;
      display: flex;
      h5 {
        font-size: 0.9rem !important;
        text-align: center;
        width: 100%;
      }
      justify-content: center;
      align-items: center;
      height: 4rem;
      flex: 1 1 20%;
      //margin: 0.25rem 0;
      &:hover {
        background-color: rgba($roybeige, 0.5);
      }
      &.active {
        background-color: $roygreen;
        border-color: $roygreen;
        color: $royblack !important;
        font-weight: 700;
      }
    }
  }
}

// everything that's not yet beige
span.expiration,
#main-menu .nav-link {
  color: $roybeige;
}

// titel and things with semi-bold font
.product-header-title span,
.maki .card-title .title,
.nigiri .location.card-text,
.ticket-detail .title {
  // font-family: 'BegumSans-Medium';
  // font-weight: 600;
}

.checkout-page {
  h1 {
    // font-family: 'BegumSans-Medium';
    //font-weight: 500 !important;
  }
  legend {
    font-size: $h5-font-size;
  }
}

.product-header-title span,
.ticket-detail .title {
  @include media-breakpoint-up(md) {
    //font-size: 3.3rem !important;
  }
}

// Sold Out Status auf Merchseite weg
.product-listing-page .sold-out-status {
  //display: none;
}

// FAQs
body.faq-page {
  h1 {
    //font-family: 'BegumSans-Medium';
  }
  .faq {
    background: transparent;
    border: none;
  }
  .faq-question button {
    padding-inline-start: 0;
    font-weight: 700;
  }
}

// CART OPTIONS  ---- SHIT FIX

.cart-option-item {
  .media {
    border-bottom: 1px solid $gray-400;
    @extend .py-3;
    align-items: normal !important;
  }
  h5,
  h5 strong {
  }
  .variant-chooser {
    .mb-3 {
      margin-bottom: 0 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .price {
      letter-spacing: -0.02rem;
      font-family: 'SSB';
    }
  }
  .variant-chooser-select {
    font-family: $font-family-sans-serif;
    select {
      margin-top: 5px !important;
    }
  }
  .media-body {
    h5 {
      max-width: 185px;
      width: 185px;
      font-family: 'SSB';
      text-transform: none;
      letter-spacing: -0.02em;
      @extend .text-truncate;
      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 100%;
        //margin-bottom: 10px !important;
      }
    }
    .mx-3 {
      text-align: right;
    }
  }
}

.checkout-page {
  .media-body {
    h5 {
      font-family: 'SSB';
      text-transform: none;
      letter-spacing: -0.02em;
      @extend .text-truncate;
    }
  }
}

// MICROS

.tt-listing td .media .media-heading,
.cart-listing td .media .media-heading {
  font-family: 'SSB';
  text-transform: none;
}

.cart-listing .remove-item {
  @extend .small;
}

.variant-chooser {
  .price {
    letter-spacing: -0.5px;
    font-family: 'SSB';
  }
}

legend .btn {
  font-family: 'SSB';
  letter-spacing: -0, 02em !important;
  text-transform: none;
}

.badge-danger.sold-out-status_sold_out {
  background-color: $royblack;
  color: $white;
}
.badge-danger.sold-out-status_starts_soon {
  background-color: transparent;
  color: $royblack;
  border: $royblack solid 1px;
}
